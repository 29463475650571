.layout {
  display: flex;
}

.company-page-container {
  /* Styles for company page container */
}

.main-content {
  /* Styles for main content */
}

.page-title {
  /* Styles for page title */
}

.page-description {
  /* Styles for page description */
}

.error-message {
  color: red;
}

.invoice-form {
  /* Styles for invoice form */
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-input {
  width: calc(33.33% - 10px);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.invoice-table-wrapper {
  margin-top: 20px;
}

.table-invoices {
  width: 100%;
  border-collapse: collapse;
}

.table-invoices th,
.table-invoices td {
  border: 1px solid #ccc;
  padding: 8px;
}

.download-pdf-button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
