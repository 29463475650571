/* CompanyPage.css */

/* Main layout styles */
.layout {
  display: flex;
  height: 100vh; /* Full viewport height */
  background: #f8f9fa; /* Light gray background like the one in your image */
}

/* Main content area */
.companyPage_container {
  flex-grow: 1; /* Takes up the remaining space */
  padding: 40px; /* More padding for spacing */
  margin-left: 250px; /* Sidebar expanded width */
  overflow-y: auto; /* Allows scrolling */
  transition: margin-left 0.4s; /* Animate the margin change */
}

/* ... other styles ... */

/* Form styles */
.company-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  max-width: 600px;
  margin: 40px auto; /* Centers the form in the page */
}

.company-form h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.company-form label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
  margin-top: 20px; /* Adds spacing between input fields */
}

.company-form input[type="text"],
.company-form input[type="tel"],
.company-form input[type="email"] {
  width: calc(100% - 16px); /* Full width minus padding */
  padding: 10px;
  margin-bottom: 10px; /* Spacing below each input */
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.company-form .form-actions {
  text-align: center; /* Centers the buttons */
  margin-top: 30px;
}

/* Button styles within the form */
.submit-button {
  background-color: #6c5ce7; /* Purple like the one in your image */
  color: white;
  border: none;
  border-radius: 20px; /* More rounded corners for the buttons */
  padding: 10px 40px;
  font-size: 16px;
  margin-right: 15px;
}

.cancel-button {
  background-color: #ff7675; /* Pinkish color like in your image */
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 40px;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #5a4cb1; /* Darker purple on hover */
}

.cancel-button:hover {
  background-color: #e84393; /* Darker pinkish on hover */
}

/* Table styles */
.company-table {
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(172, 170, 170, 0.19509810760241597) 23%
  );
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  margin-top: 20px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.company-table thead th {
  background-color: #eee;
  color: #333;
  font-weight: 500;
  padding: 15px 10px;
  text-align: left;
}

.company-table tbody td {
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
}

.add-button {
  padding: 10px 20px;
  background-color: #5c6bc0;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 30px; /* More pronounced rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.add-button:hover {
  background-color: #3949ab; /* A shade darker for hover state */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.action-button:focus + .dropdown-content,
.action-button:active + .dropdown-content,
.dropdown-content:focus-within {
  display: block; /* Show dropdown */
}

/* Action buttons in the table */
.action-button {
  background: transparent;
  border: none;
  color: #333;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.action-button {
  /* Style for the button that triggers the dropdown */
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dropdown content style update */
.dropdown-content {
  /* Initial state of the dropdown */
  display: none;
  position: absolute;
  right: 0; /* Align dropdown to the right of action button */
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.action-buttons {
  position: relative; /* Needed to position dropdown */
}
.dropdown-content.active {
  /* Make dropdown visible */
  display: block;
}

.dropdown-content button {
  color: #333;
  padding: 12px 16px;
  text-align: left;
  background-color: transparent;
  border: none;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-content button:hover {
  background-color: #f5f5f5; /* Light grey background for button hover */
}

/* Table adjustments for action column */
.company-table td.actions {
  width: 1%; /* Min width for the actions column */
  white-space: nowrap; /* Keep the actions together */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .companyPage_container {
    padding: 20px;
    margin-left: 0; /* Full width on small screens */
  }

  .main-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-button {
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .layout .sidebar.collapsed + .companyPage_container {
    margin-left: 100px; /* Adjust to the collapsed sidebar width */
  }
}
