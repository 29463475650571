/* SharedLayout.css */

.layout {
  display: flex;
  height: 100vh;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  gap: 10px;
}

.search-bar input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.refresh-button,
.add-button {
  padding: 8px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.refresh-button:hover,
.add-button:hover {
  background-color: #0056b3;
}

/* PeoplePage.css */

.people-table {
  width: 100%;
  border-collapse: collapse;
}

.people-table th,
.people-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.people-table th {
  background-color: #f8f9fa;
}

.action-buttons {
  display: flex;
  gap: 5px;
}

.relative-container {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content.show {
  display: block;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  text-align: left;
}

.person-form {
  margin-top: 20px;
}

.user-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.user-form label {
  flex: 1 1 100%;
}

.user-form input,
.user-form button {
  flex: 0 1 calc(50% - 5px);
}

.submit-btn {
  flex-basis: 100%;
}

.relative-container > button {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 24px;
  outline: none;
}

/* End of CSS */
