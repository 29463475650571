/* Container for all charts */
.charts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* Add some space between chart boxes */
  }
  
  /* Each individual chart box */
  .chart-box {
    width: calc(50% - 10px); /* Each box takes up half of the available space with some gap */
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Light background color */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  }
  
  /* Chart element itself */
  .chart {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Match the border radius of the box */
  }
  
  /* Title for each chart */
  .chart-title {
    text-align: center;
    margin: 10px 0;
    color: #333; /* Darker text color */
    font-size: 1.2rem;
  }
  
  /* Add hover effect to chart boxes */
  .chart-box:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Add a lifted effect on hover */
  }
  
  /* Adjust legend styles */
  .chart .legend {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .chart .legend li {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .chart .legend li span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  /* Adjust tooltip styles */
  .chart .tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .chart-box {
      width: 100%; /* Full width on smaller screens */
    }
  }
  