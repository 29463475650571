/* Include your other styles from SharedLayout.css and any additional styles you need */

/* Business Page specific styles */
.relative-container {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 20px; /* Adjust as needed */
}

/* Show the dropdown menu when active */
.relative-container .dropdown-content.show {
  display: block;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

/* Styles for the action button that triggers the dropdown */
.relative-container > button {
  border: none;
  background: none;
  padding: 5px;
  cursor: pointer;
  color: black; /* Make sure this color stands out against your button's background color */
  font-size: 16px; /* Adjust as needed to ensure visibility */
  display: flex; /* Centers the content if there's a height specified */
  align-items: center; /* Centers the content vertically */
  justify-content: center; /* Centers the content horizontally */
}

.action-button {
  color: black; /* Or any color that stands out on your page */
  font-size: 24px; /* Makes "..." larger and more visible */
  line-height: 1; /* Adjusts the line height to fit the content */
  display: inline-block; /* Helps to contain the "..." */
  text-align: center; /* Centers the text inside the button */
}

/* Table styles */
table {
  /* Add your styles for table */
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(172, 170, 170, 0.19509810760241597) 23%
  );
}

/* Add any other specific styles you need for the Business page */
