/* SharedLayout.css */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.companyPage_container {
  flex-grow: 1;
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(88, 73, 40, 0.13067233729429273) 100%
  );
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  gap: 10px;
  align-items: center; /* Center items vertically */
}

.search-button {
  padding: 8px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-bar input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.refresh-button,
.add-button {
  padding: 8px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.refresh-button:hover,
.add-button:hover {
  background-color: #0056b3;
}

/* PeoplePage.css */

.people-table {
  width: 100%;
  border-collapse: collapse;
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(172, 170, 170, 0.19509810760241597) 23%
  );
}

.people-table th,
.people-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.people-table th {
  background-color: #f8f9fa;
}

.action-buttons {
  display: flex;
  gap: 5px;
}

.relative-container {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content.show {
  display: block;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  text-align: left;
}

.person-form {
  margin-top: 20px;
}

.person-form form {
  display: flex;
  flex-wrap: wrap; /* Allow form elements to wrap */
  gap: 10px;
}

.person-form label {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Take full available width */
}

.person-form input,
.person-form button {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1; /* Take full available width */
}

.person-form button[type="submit"] {
  background-color: #28a745;
  color: white;
}

.person-form button[type="button"] {
  background-color: #dc3545;
  color: white;
}
