/* Sidebar.css */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/* Resets */
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Full-height sidebar with a dark background */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Expanded width */
  height: 100vh;
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,67,121,1) 64%, rgba(0,212,255,1) 100%);   padding: 10px 0; */
  background: linear-gradient(
    148deg,
    rgba(38, 184, 209, 1) 0%,
    rgba(226, 49, 49, 1) 100%
  );
  transition: width 0.4s; /* Smooth transition for expanding/collapsing */
  overflow-x: hidden; /* Hide horizontal overflow when collapsed */
}

/* When collapsed */
.sidebar.collapsed {
  width: 100px; /* Compact width */
}

/* Header of the sidebar */
.sidebar-header {
  text-align: center;
  margin: 10px 0;
  color: #ffffff;
  position: relative;
}

/* Toggle button */
.toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

/* Styles for nav-items */
.nav-item {
  color: #ffffff;
  padding: 12px 20px; /* Increased padding for better touch targets */
  text-decoration: none;
  display: block; /* Ensures each nav item is on its own line */
  font-family: "Roboto", sans-serif; /* Uses the imported font */
  font-size: 16px; /* Sets a comfortable reading size for the text */
  font-weight: 500; /* Medium font weight for readability */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and active states */
  overflow: hidden; /* Hides text that goes beyond the width */
  text-overflow: ellipsis; /* Shows ellipsis (...) for overflowed text */
  white-space: nowrap; /* Prevents text wrapping */
}

/* Icons - placeholder, replace with actual icons */
.nav-item:before {
  font-family: "Font Awesome 5 Free"; /* Adjust if using different icons */
  display: block;
  font-weight: 900;
  margin-bottom: 5px;
  /* Example icon content */
}

/* Hover effect */
.nav-item:hover {
  color: #007bff;
}

.nav-item:hover,
.nav-item.active {
  background-color: #1f5a7a; /* Darker background on hover/active */
  color: #a0d2eb; /* Lighter text color on hover/active */
}

/* Style for the nav container */
.nav {
  display: flex;
  flex-direction: column; /* Ensures nav items are laid out vertically */
  align-items: flex-start; /* Aligns items to the left */
  padding-left: 0; /* Removes default padding */
}

/* Active link style */
.active {
  color: #007bff;
}

/* Adjust sidebar size on hover */
.sidebar:hover {
  width: 250px; /* Same as expanded width */
}

/* Hide labels when collapsed */
.sidebar.collapsed .nav-item {
  padding: 10px;
  text-align: left; /* Align to left or center as preferred */
}
