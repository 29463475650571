/* HomePage.css */

.new-home-container {
  color: #fff;
  background: linear-gradient(
    145deg,
    #011627,
    #002d42
  ); /* Create a gradient background */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  text-align: center; /* Center text alignment */
  padding: 20px;
  min-height: 100vh; /* Full screen height */
  position: relative; /* Enable absolute positioning for child elements */
}

.new-home-content h1 {
  font-size: 4rem; /* Large text for the heading */
  margin-bottom: 0.5em; /* Spacing below the heading */
}

.new-home-content p {
  max-width: 600px; /* Optimal line length for readability */
  margin-bottom: 2em; /* Spacing below the paragraph */
}

.new-home-buttons .btn {
  margin: 10px;
  padding: 15px 30px; /* Larger buttons */
  border: none;
  cursor: pointer;
  font-size: 1rem; /* Button text size */
  border-radius: 5px; /* Rounded corners for buttons */
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); /* Subtle shadow for depth */
  transition: transform 0.2s ease-in-out; /* Smooth transform transition */
}

.btn-primary {
  background-color: #22d1ee; /* Vibrant blue color */
  color: white;
}

.btn-primary:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.btn-secondary {
  background-color: #607b96; /* Muted secondary button */
  color: white;
}

.btn-secondary:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.new-home-image {
  position: relative;
  width: 80%;
  max-width: 800px; /* Max image width */
  margin: 3em auto; /* Center image with margin */
}

.new-home-image img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded corners for the image */
}

/* Dynamic geometric shapes */
.background-shape {
  /* No changes required here from your original CSS */
}

.shape1,
.shape2,
.shape3 {
  /* Slightly transparent dark blue shapes */
  background-color: rgba(4, 45, 77, 0.85);
  /* More complex shadows for a dramatic effect */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.shape1 {
  /* Positioning and size for the first shape */
  width: 250px;
  height: 250px;
  border-radius: 50%; /* Circular shape */
  top: -10%;
  left: -10%;
}

.shape2 {
  /* Positioning and size for the second shape */
  width: 150px;
  height: 150px;
  top: 20%;
  right: -5%;
}

.shape3 {
  /* Positioning and size for the third shape */
  width: 300px;
  height: 300px;
  bottom: -15%;
  left: 50%;
  transform: translateX(-50%); /* Center shape horizontally */
}

.features-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 50px auto; /* Space above and below the feature section */
}

.feature-item {
  flex: 1; /* Each feature will take equal space */
  padding: 20px;
  text-align: center;
}

.feature-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 200px; /* Set a fixed height or as needed */
  margin-bottom: 20px; /* Space between the image and the text */
}

/* Text styles for the feature titles and descriptions */
.feature-title {
  font-size: 2rem;
  color: #30e3ca; /* Matching the title color from the screenshot */
  margin-bottom: 15px; /* Space below the title */
}

.feature-description {
  font-size: 1rem;
  color: #ccc; /* Lighter text color for the description */
}

/* HomePage.css */

/* ... existing styles ... */

/* Styles for the pricing section */
.pricing-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-top: 50px; /* Space above the pricing section */
  /* background: linear-gradient(135deg, #647ed6, #d0b9f4); */
}

.pricing-item {
  background: #011627;
  padding: 20px;
  margin: 0 10px;
  border-radius: 8px;
  width: 300px; /* Width of each pricing card */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Shadow for depth */
  text-align: center;
  background: linear-gradient(135deg, #3f4d78, #d0b9f4);
}

.pricing-price {
  font-size: 3rem;
  color: #22d1ee; /* Vibrant blue color */
}

.pricing-price span {
  font-size: 1.5rem; /* Smaller font size for '/month' */
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin: 20px 0; /* Spacing around the features list */
}

.pricing-features li {
  margin-bottom: 10px; /* Spacing between features */
  color: #30e3ca; /* Light blue/greenish color for checkmarks and text */
}

.btn-primary {
  display: block; /* Make the button a block to fill container */
  width: 80%; /* Relative width to container */
  margin: 10px auto; /* Center button */
  /* ... existing button styles ... */
}

/* HomePage.css */

/* ... your existing styles ... */

/* Styles for the CTA section */
.cta-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #011627; /* Dark background */
  padding: 30px 50px; /* Increased padding for more space */
  margin: 40px 0;
}

.cta-text {
  color: #fff;
  font-size: 2rem; /* Increased font size for visibility */
  flex-grow: 1; /* Allows text to take up available space */
  margin-right: 30px; /* Increased space between text and button */
}

.btn-cta {
  background-color: #22d1ee; /* Vibrant blue color */
  color: #fff;
  padding: 15px 30px; /* Larger padding for a bigger button */
  font-size: 1.25rem; /* Larger font size for the button */
  text-transform: uppercase; /* Makes the button text uppercase */
  font-weight: bold; /* Makes the button text bold */
  border: none; /* Remove border if it exists */
  border-radius: 5px; /* Rounded corners for the button */
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); /* Optional: Adds a subtle shadow for depth */
  transition: transform 0.2s ease-in-out; /* Smooth transform transition for interactions */
}
/* HomePage.css */

/* ... existing styles ... */

/* Styles for the customer review section */
.review-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-x: auto; /* Makes the section scrollable horizontally */
  gap: 20px; /* Spacing between review items */
}

.review-item {
  min-width: 250px; /* Minimum width for each review card */
  background: #011627;
  padding: 20px;
  margin: 0 10px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
}

.review-quote {
  font-style: italic;
  color: #22d1ee;
}

.review-name {
  color: #fff;
  margin-top: 10px;
}

.review-rating {
  color: #ffd700; /* Gold color for star rating */
  font-size: 1.25rem;
  margin-top: 5px;
}

/* ... existing styles ... */

@media (max-width: 768px) {
  .review-section {
    flex-direction: column;
  }

  .review-item {
    min-width: 100%; /* Full width on smaller screens */
  }
}

/* Ensure the button fits content, add responsive behavior if needed */
@media (max-width: 768px) {
  .cta-section {
    flex-direction: column;
  }

  .btn-cta {
    width: 100%; /* Full width button on smaller screens */
    margin-top: 20px; /* Space above the button when stacked */
  }
}

/* ... your existing styles ... */

/* ... existing styles ... */

/* ContactForm.css */
.contact-us-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  align-items: start;
  background-color: #011627; /* Background color similar to the image */
  background-image: url("https://cdn1.vectorstock.com/i/1000x1000/32/00/green-blur-background-vector-4793200.jpg"); /* Blurred background image */
  background-size: cover;
  background-position: center;
  padding: 50px;
  border-radius: 10px;
  margin: 50px auto;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.contact-us-section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  filter: blur(10px);
  z-index: -1;
}

.contact-info {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px); /* Blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
}

.contact-info h2,
.contact-info p,
.contact-info a,
.contact-info .social-links {
  margin-bottom: 0.5rem;
}

.contact-info a {
  color: #30e3ca;
  text-decoration: none;
}

.contact-form {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px); /* Blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Rest of your existing styles */
/* Responsive adjustments for global layout */
@media (max-width: 1024px) {
  .new-home-content h1 {
    font-size: 3rem; /* Smaller font size for medium screens */
  }

  .features-container,
  .pricing-container {
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(135deg, #647ed6, #d0b9f4); */
  }

  .feature-item,
  .pricing-item {
    margin: 10px; /* Increase spacing for stacked layout */
  }

  .contact-us-section {
    grid-template-columns: 1fr; /* Stack form and contact info vertically */
  }
}

/* Further adjustments for smaller screens */
@media (max-width: 768px) {
  .new-home-container {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .new-home-content h1 {
    font-size: 2.5rem; /* Even smaller font size for small screens */
  }

  .new-home-content p,
  .feature-description,
  .pricing-features li,
  .contact-info,
  .contact-form {
    font-size: 0.9rem; /* Adjust font size for readability */
  }

  .new-home-buttons .btn,
  .btn-cta {
    padding: 12px 24px; /* Adjust button size for easier interaction */
    font-size: 0.9rem; /* Smaller font size for buttons */
  }

  .new-home-image,
  .feature-image {
    width: 95%; /* Increase image width to better fit small screens */
  }

  .shape1,
  .shape2,
  .shape3 {
    display: none; /* Optionally hide shapes on very small screens for simplicity */
  }
}

/* Adjustments for extremely small screens */
@media (max-width: 480px) {
  .new-home-content h1 {
    font-size: 2rem; /* Further reduce for very small screens */
  }

  .new-home-content p,
  .feature-description,
  .pricing-features li {
    font-size: 0.8rem; /* Smaller text for compact spaces */
  }

  .cta-section,
  .review-section {
    flex-direction: column; /* Stack elements vertically */
  }

  .btn-cta,
  .review-item {
    width: 100%; /* Full width for buttons and review items */
  }
}

/* Responsive adjustments for contact form */
@media (max-width: 768px) {
  .contact-form {
    grid-template-columns: 1fr; /* Stack form fields vertically */
  }
}
