/* Specific styles for the ClientPage */
.client-content {
  flex-grow: 1;
  padding: 40px;
  margin-left: 250px; /* Adjust the margin to match the width of the sidebar */
  overflow-y: auto;
  transition: margin-left 0.4s;
}

.client-header {
  margin-bottom: 20px;
}

.client-header h1 {
  color: #333;
  font-size: 1.75rem;
}

.client-form {
  margin-bottom: 40px;
}

.client-form select,
.client-form button {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.client-form button {
  background-color: #28a745; /* Green for a 'success' action like adding a client */
  color: white;
  border: none;
  cursor: pointer;
}

.client-form button:hover {
  background-color: #218838; /* A darker green on hover */
}

.client-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.client-table th,
.client-table td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.client-table th {
  background-color: #f2f2f2;
}

/* Client Page Main Layout Enhancements */
.layout {
  display: flex;
  min-height: 100vh;
  background-color: #f4f7f6;
}

.companyPage_container {
  flex-grow: 1;
  padding: 20px;
}

.main-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Styling for Enhanced User Experience */
form {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
}

select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fafafa;
  transition: all 0.3s ease-in-out;
}

select:focus {
  outline: none;
  border-color: #77aaff;
  box-shadow: 0 0 0 2px rgba(119, 170, 255, 0.5);
}

button {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #003d82;
}

/* Client Table Styling for Consistency */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(172, 170, 170, 0.19509810760241597) 23%
  );
}

th,
td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

/* Positioning the dropdown content */
.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  right: 0; /* Align to the right side of the action-buttons */
  margin-top: 5px; /* Give some space between the button and dropdown */
}

/* Style for the dropdown items */
.dropdown-content button {
  color: black;
  padding: 8px 10px;
  text-align: left;
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  text-align: start;
}

.dropdown-content button:hover {
  background-color: #ddd; /* Highlight on hover */
}

.action-buttons {
  position: relative;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .layout {
    flex-direction: column;
  }

  .client-content {
    margin-left: 0;
  }
}
