/* Subscription Page Layout */
.layout {
  display: flex;
  min-height: 100vh;
}

.companyPage_container {
  flex-grow: 1;
  padding: 20px;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
}

/* Subscription Plans Styling */
.plans-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.plan {
  flex: 0 1 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* background-color: #f8f9fa; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(172, 170, 170, 0.19509810760241597) 23%
  );
}

.plan:hover {
  transform: translateY(-5px);
}

.plan h2 {
  color: #333;
  margin-bottom: 15px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.plan p {
  margin-bottom: 15px;
}

.plan ul {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.plan ul li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.plan button {
  margin-top: auto;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.plan button:hover {
  opacity: 0.9;
}

/* Billing Toggle */
.billing-toggle {
  text-align: center;
  margin-bottom: 40px;
}

.billing-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.billing-button.active,
.billing-button:hover {
  background-color: #0056b3;
}

/* Toast Notification */
.ToastContainer {
  font-size: 14px;
}
