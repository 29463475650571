.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-info {
  color: #fff; /* Metin rengi */
  margin-bottom: 20px; /* Form ve metin arasında boşluk */
}

.login-info h2 {
  margin-bottom: 10px; /* Başlık ve paragraf arasında boşluk */
}
.three-d-text {
  font-size: 40px; /* Metin boyutu */
  color: #fff; /* Metin rengi */
  font-weight: bold; /* Metni kalın yapar */
  text-transform: uppercase; /* Metni büyük harflere dönüştürür */
  text-shadow: 1px 1px 0 #000, 2px 2px 0 #000, 3px 3px 0 #000,
    /* 3D gölge efekti */ 4px 4px 0 #000;
  transform: perspective(500px) rotateY(20deg); /* 3D perspektif ve yatay eğim */
  display: inline-block; /* Transform özelliğini etkinleştirmek için gerekli */
}
.icons-container {
  display: flex;
  justify-content: space-around; /* İkonları eşit aralıklarla dağıt */
  padding: 50px 0; /* Üst ve alt boşluk */
}

.test {
  width: 300px; /* İkon genişliğini küçült */
  stroke: currentColor; /* SVG çizgi rengini mevcut font rengiyle eşleştir */
  stroke-width: 1.5; /* SVG çizgi kalınlığını ayarla */
}

.login-left {
  flex: 1;
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  /* background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(21, 48, 50, 1) 74%
  ); */

  /* background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(29, 250, 253, 1) 55%,
    rgba(252, 176, 69, 1) 91%
  ); */
  background: linear-gradient(
    90deg,
    rgba(168, 180, 58, 1) 32%,
    rgba(29, 250, 253, 1) 68%,
    rgba(252, 176, 69, 1) 91%
  );
  overflow: auto; /* İçerik alanı dışına taşarsa kaydırmayı etkinleştirir */
}
.test2 {
  color: #000000; /* Change text color to black */
  font-size: 16px; /* Set the font size */
  font-family: "Arial", sans-serif; /* Choose a font family, if needed */
  line-height: 1.5; /* Adjust the line height for better readability */
  margin: 10px 0; /* Add some margin if necessary */
  /* Add other styles as needed */
}

.login-left img {
  max-width: 100%; /* Resmi genişliğine göre ölçeklendir */
  padding-right: 1px;
  margin-bottom: 30px; /* Resim ile metin arasında boşluk bırakır */
}

.login-right {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 80px;
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(50, 44, 21, 1) 57%
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3); /* Hafif saydam bir sınır */
}

.login-form {
  width: 200%;
  height: 60%;
  max-width: 300px; /* Formun maksimum genişliğini ayarlar */
  background: linear-gradient(
    148deg,
    rgba(38, 184, 209, 1) 0%,
    rgba(226, 49, 49, 1) 100%
  );
  padding: 20px;
  border-radius: 10px; /* Formun köşelerini yuvarlak yapar */
}
.login-left p {
  text-align: center; /* Metni ortalar */
  margin-top: auto; /* Metni ve resmi ayıran boşluğu maksimize eder */
}

.inputClass {
  color: #151313; /* Kırmızı renk */
  font-family: Arial, sans-serif;
  font-size: 9px; /* 24 piksel boyutunda */
  font-weight: bold; /* Kalın metin */
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8; /* Giriş kutularının arka plan rengini ayarlar */
}

.inputLabel {
  color: #120f0f; /* Kırmızı renk */
  font-family: Arial, sans-serif;
  font-size: 24px; /* 24 piksel boyutunda */
  font-weight: bold; /* Kalın metin */
}

.login-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50; /* Giriş butonunun arka plan rengini ayarlar */
  color: white;
  cursor: pointer;
}

/* Okları temsil edecek sınıf */
.arrow {
  position: relative; /* Pozisyon için referans noktası */
  width: 100%; /* Genişlik */
  height: 2px; /* Ok çizgisinin kalınlığı */
  background: #fff; /* Ok çizgisinin rengi */
  margin: 20px 0; /* Yukarı ve aşağıya boşluk */
}

/* Ok ucu için pseudo-element */
.arrow::after {
  content: "";
  position: absolute;
  top: -5px; /* Ok ucunu hizalama */
  right: 0; /* Sağa yasla */
  border-width: 5px; /* Ok ucunun boyutu */
  border-style: solid;
  border-color: transparent transparent transparent #fff; /* Sağa işaret eden üçgen */
}

/* Metin için ayrı bir sınıf */
.arrow-text {
  position: absolute;
  top: -20px; /* Metni okun üstüne yerleştir */
  right: 10px; /* Ok ucunun biraz sağına */
  color: #fff; /* Metin rengi */
  font-size: 14px; /* Metin boyutu */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .login-page {
    flex-direction: column;
  }

  .login-left,
  .login-right {
    width: 100%;
    max-width: 100%;
    flex: none;
  }
}
