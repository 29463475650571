/* Dropdown styles */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .relative-container {
    position: relative;
  }
  
  .dropdown-content button {
    width: 100%;
    text-align: left;
    padding: 6px 0;
  }
  
  .dropdown-content button:hover {
    background-color: #ddd;
  }
  
  .show {
    display: block;
  }
  