/* Main Layout and Container */
.layout {
  display: flex;
  height: 100vh;
}

.companyPage_container {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px; /* Adjust based on your sidebar width */
}

.main-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Product Form Styling */
form {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

form input,
form select,
form textarea,
form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fafafa;
}

form button {
  background-color: #0056b3; /* Primary button color */
  color: white;
  border: none;
}

form button:hover {
  background-color: #003d82; /* Darker shade on hover */
}

/* Product Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th,
table td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
  color: #333;
}

/* Dropdown Menu Styling */
.dropdown-menu {
  display: none; /* Hide by default, will be shown on click */
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdown-menu button {
  color: black;
  padding: 12px;
  text-align: left;
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
}

.dropdown-menu button:hover {
  background-color: #ddd;
}

.action-buttons {
  position: relative;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .layout {
    flex-direction: column;
  }

  .companyPage_container {
    padding: 20px;
  }
}
