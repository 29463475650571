/* Reset margins and padding for a clean layout */
html,
body,
#root,
.signup-page {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Apply a dark background style to the body */
body {
  background-color: #121212;
}

/* Style the full page container */
.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #001301; /* Green background */
}

/* Left section styles */
.signup-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align to the start of the flex container */
  align-items: center;
  flex: 1; /* Take up half of the space */
  padding: 20px;
  overflow: auto; /* In case the content overflows */
}

/* Profile section styling */
.profile-section {
  width: 100%;
  text-align: center;
}

/* Image container styling */
.image-container {
  margin: 10px 0;
}

/* Profile image styling */
.profile-image {
  width: 50%; /* Adjust as needed */
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px; /* Space below the image */
}

.fullStack {
  font-weight: bold;
  color: white;
  /* background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(21,48,50,1) 74%);  */
  font-size: 24px; /* Adjust as needed */
  margin-bottom: 20px; /* Space below the title */
}

/* Info sections styling */
.info-sections {
  display: flex;
  flex-wrap: wrap; /* Allow the items to wrap as needed */
  justify-content: space-around; /* Distribute items evenly */
  gap: 20px; /* Space between the items */
  width: 60%; /* Adjust the width as needed */
}

/* Info container styling */
.info-container {
  /* background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(172,170,170,0.19509810760241597) 23%, rgba(115,171,142,1) 43%, rgba(161,169,67,1) 60%, rgba(27,168,99,0.3267507686668417) 74%);   padding: 20px; */
  flex: 1 1 240px; /* Adjust flex-basis to control initial size */
  max-width: 440px; /* Control the maximum width for smaller boxes */
  margin-bottom: 20px; /* Space between rows of boxes */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff; /* For better visibility against the gradient */
}
.info-container:last-child:nth-child(odd) {
  max-width: 80%;
}

/* Right section for the signup form */
/* Right section for the signup form */
./* Right section for the signup form */
.signup-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1; /* Ensure it takes up an equal amount of space as the left section */
  padding: 20px;
  background-color: rgba(
    255,
    255,
    255,
    0.05
  ); /* Slightly transparent white for a soft look */
  backdrop-filter: blur(10px); /* Soft blur effect for background */
  border-radius: 8px; /* Rounded corners for a modern look */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border */
  color: #fff; /* White text color */
  max-width: 500px; /* Maximum width to keep the form from stretching too wide on large screens */
}

/* Styling specific to form elements to enhance the visual coherence */
/* Right section for the signup form */
.signup-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1; /* Ensure it takes up an equal amount of space as the left section */
  padding: 20px;
  margin-bottom: 400px;
  margin-right: 100px;
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(186, 78, 157, 1) 65%,
    rgba(252, 70, 107, 1) 100%
  ); /* Slightly transparent white for a soft look */
  backdrop-filter: blur(10px); /* Soft blur effect for background */
  border-radius: 8px; /* Rounded corners for a modern look */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border */
  color: #de2828; /* White text color */
  max-width: 400px; /* Maximum width to keep the form from stretching too wide on large screens */
}

/* Styling specific to form elements to enhance the visual coherence */
.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"] {
  padding: 10px;
  margin-bottom: 15px; /* Space between form fields */
  border-radius: 4px; /* Slightly rounded borders for input fields */
  border: 1px solid #ccc; /* Light grey border */
  width: 100%; /* Full width to fill the form container */
  box-sizing: border-box; /* Ensure padding doesn't affect the final width */
}
.inputClassSignup {
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(88, 73, 40, 0.13067233729429273) 100%
  );
}
.signup-button {
  padding: 10px 20px;
  background-color: #4caf50; /* Match the page background for continuity */
  color: #fff; /* White text color for readability */
  border: none; /* No border for a cleaner look */
  border-radius: 4px; /* Consistency with input field rounding */
  cursor: pointer; /* Indicate that the button is clickable */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.signup-button:hover {
  background-color: #367c39; /* Darker shade on hover for visual feedback */
}

/* Rest of the styles remain the same */

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.9);
  }
  25% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.7), 0 0 30px rgba(0, 255, 0, 0.5);
  }
  75% {
    box-shadow: 0 0 20px rgba(0, 0, 255, 0.7), 0 0 40px rgba(0, 0, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.9);
  }
}
